<template>
  <div>
    <div class="a">
      <img class="one" src="../../../assets/img/WAP产品分类4-精雕系列_02.jpg" />
    </div>
    <div class="b">
      <img class="one" src="../../../assets/img/WAP产品分类4-精雕系列_03.jpg" />
    </div>
    <div class="c">
      <img class="one" src="../../../assets/img/WAP产品分类4-精雕系列_04.jpg" />
    </div>
    <div class="d">
      <img class="one" src="../../../assets/img/WAP产品分类4-精雕系列_05.jpg" />
    </div>
    <div class="e">
      <img class="one" src="../../../assets/img/WAP产品分类4-精雕系列_06.jpg" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.e{
    height: 413px;
}
.d{
    height: 394px;
}
.c{
    height: 455px;
}
.b{
    height: 175px;
}
.a{
    height: 170px;
}
</style>